import { isLocalHost, isStaging, windowExists } from "@internal/utils-browser";
import { useBrowserClasses } from "@vp/swan";
import Cookies from "js-cookie";
import { useEffect, useMemo } from "react";

export const useClientsideSetup = () => {
    const windowDefined = windowExists();

    useBrowserClasses(true);

    // This hook is added to to make sure the zooming gesture doesn't interfere with Safari accessibility zoom.
    // See https://use-gesture.netlify.app/docs/gestures/#about-the-pinch-gesture
    useEffect(() => {
        const preventGesture = (e: Event) => {
            e.preventDefault();
        };
        document.addEventListener("gesturestart", preventGesture);
        document.addEventListener("gesturechange", preventGesture);
        return () => {
            document.removeEventListener("gesturestart", preventGesture);
            document.removeEventListener("gesturechange", preventGesture);
        };
    }, []);

    useMemo(() => {
        if (windowDefined) {
            if (typeof window.IntersectionObserver === "undefined") {
                // this is used by swan
                import("intersection-observer");
            }

            // rIC shims from google https://developer.chrome.com/blog/using-requestidlecallback#checking_for_requestidlecallback
            // we could simply check for rIC existence but this gives control back to the browser to resolve any UI stuff until setTimeout fires
            // which at least partially does what we want for rIC
            window.requestIdleCallback =
                window.requestIdleCallback ||
                function requestIdleCallbackShim(cb) {
                    const start = Date.now();
                    return setTimeout(() => {
                        cb({
                            didTimeout: false,
                            timeRemaining() {
                                return Math.max(0, 50 - (Date.now() - start));
                            }
                        });
                    }, 1);
                };

            window.cancelIdleCallback =
                window.cancelIdleCallback ||
                function cancelIdleCallbackShim(id) {
                    clearTimeout(id);
                };

            if (isLocalHost() || isStaging()) {
                /**
                 * This silences the ctc-reader warnings we see when running localhost
                 * https://gitlab.com/vistaprint-org/personalization/content-personalization/customer-treatment-configurator/decision-engine-reader#local-development
                 */
                Cookies.set(
                    "userContext",
                    // eslint-disable-next-line max-len
                    "{%22ctc%22:{%22v1%22:[%22CH_Consumer_products_Tests_2%22%2C%22CH_Consumer_products_Tests%22%2C%22employee_discount%22%2C%22Winning_Outdoors_15Off%22%2C%22design_services_hub_inspiration%22%2C%22buyers_sg_full_width%22%2C%22site_tagging_repeat_customer%22%2C%22registered_repeat_user_hero%22]}%2C%22userProfile%22:{%22v1%22:{%22couponCode%22:%22NEW25%22%2C%22industry_l1_id%22:%22d4f8659d324d47f39fbaa5e247b73204%22%2C%22culture%22:%22en-US%22}}}",
                    { domain: isLocalHost() ? "localhost" : "staging.five.studio.design.vpsvc.com" }
                );
            }
        }
    }, [windowDefined]);
};
